import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import AppLayout from '../layout/app-layout.vue'
import AuthLayout from '../layout/auth-layout.vue'
import store from '@/store';
import Product from '../pages/order/products.vue';
import Checkout from '../pages/order/checkout/index.vue';


const routes: Array<RouteRecordRaw> = [
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'login' }
  },

  {
    name: 'store',
    path: '/store',
    component: AppLayout,
    children: [
      {
        name: 'dashboard',
        path: ':UserUUID/dashboard',
        component: () => import('../pages/404-pages/VaPageNotFoundSimple.vue'),
      },
      {
        name: 'services',
        path: ':UserUUID/services',
        component: () => import('../pages/store/boost/BoostStore.vue'),
      },
      {
        name: 'notification',
        path: ':UserUUID/notification',
        component: () => import('../pages/store/alert/alertstore.vue'),
      },
      {
        name: 'Client',
        path: ':UserUUID/clients',
        component: () => import('../pages/store/customer/CustomerStore.vue'),
      },
      {
        name: 'state',
        path: ':UserUUID/state',
        component: () => import('../pages/user/profile/State.vue')
      },
      {
        name: 'orders',
        path: ':UserUUID/orders',
        component: () => import('../pages/store/orders/Orders.vue')
      },
      {
        name: 'profile',
        path: ':UserUUID/profile',
        component: () => import('../pages/user/profile/Profile.vue')
      },
      {
        name: 'ingredients',
        path: ':UserUUID/ingredients',
        component: () => import('../pages/store/ingredients/ingredients.vue')
      },
      {
        name: 'supplements',
        path: ':UserUUID/supplements',
        component: () => import('../pages/store/supplements/supplements.vue')
      },
      {
        name: 'categories',
        path: ':UserUUID/categories',
        component: () => import('../pages/store/categories/ListCategories.vue')
      },
      {
        name: 'tables',
        path: ':UserUUID/tables',
        component: () => import('../pages/store/table/ListTables.vue')
      },
      {
        name: 'fees',
        path: ':UserUUID/fees',
        component: () => import('../pages/store/fees/Fees.vue')
      },
      {
        name: 'qrcode',
        path: ':UserUUID/qrcode',
        component: () => import('../pages/qr-code/QrCode.vue')
      },
      {
        name: 'promos',
        path: ':UserUUID/promos',
        component: () => import('../pages/store/promo/ListPromos.vue')
      },
      {
        name: 'promocode',
        path: ':UserUUID/promo-code',
        component: () => import('../pages/store/promo-code/ListPromoCode.vue')
      },
      {
        name: 'detailcategorie',
        path: ':UserUUID/categories/:id',
        component: () => import('../pages/store/categories/DetailCategorie.vue')
      },
      {
        name: 'products',
        path: ':UserUUID/products',
        component: () => import('../pages/store/products/ListProducts.vue')
      },
      {
        name: 'detailproduct',
        path: ':UserUUID/products/:id',
        component: () => import('../pages/store/products/DetailProduct.vue')
      },
      {
        name: 'createproduct',
        path: ':UserUUID/products/create',
        component: () => import('../pages/store/products/CreateProduct.vue'),
      },
      {
        name: 'profile',
        path: ':UserUUID/profile',
        component: () => import('../pages/user/profile/Profile.vue')
      },
      {
        name: 'images-bank',
        path: ':UserUUID/images-bank',
        component: () => import('../pages/store/images/ImagesBank.vue')
      },
      {
        name: 'contact',
        path: ':UserUUID/contact',
        component: () => import('../pages/contact/Contact.vue')
      },
      {
        name: 'loyality',
        path: ':UserUUID/loyality',
        component: () => import('../pages/store/loyality/Loyality.vue')
      },
      {
        name: 'copyProduct',
        path: ':UserUUID/copyProduct',
        component: () => import('../pages/store/products/CopyProduct.vue')
      },
      // {
      //   name: 'Orderproduct', // Choose a unique name for the route
      //   path: ':UserUUID/order-products', // Define the URL pattern for the product detail page
      //   component: Product, // Use the imported component for the page
      // },
      {

        name: 'AddToCart', // Choose a unique name for the route
        path: ':UserUUID/add-to-cart', // Define the URL pattern for the product detail page
        component: Product, // Use the imported component for the page

      },
      {

        name: 'Checkout', // Choose a unique name for the route
        path: ':UserUUID/checkout', // Define the URL pattern for the product detail page
        component: Checkout, // Use the imported component for the page

      },
    ]
  },
  {
    name: 'admin',
    path: '/admin',
    component: AppLayout,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/isAuth']) {
        return next({ name: 'login' })
      }
      next()
    },
    children: [{
      name: 'users',
      path: ':UserUUID/users',
      component: () => import('../pages/store/users/ListUsers.vue'),
      beforeEnter: (to, from, next) => {
        if (store.getters['auth/role']) {
          return next()
        }
        next({ name: 'dashboard' })
      }
    },
    {
      name: 'copyCat',
      path: ':UserUUID/copyCat',
      component: () => import('../pages/store/products/CopyCat.vue'),
      beforeEnter: (to, from, next) => {
        if (store.getters['auth/role']) {
          return next()
        }
        next({ name: 'dashboard' })
      }
    },]
  },

  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/login/Login.vue'),
        beforeEnter: (to, from, next) => {
          if (store.getters['auth/isAuth']) {
            // befor go to dashboard we need to check the role
            // case is super admin
            if (store.getters['auth/role']) {
              return next({ name: 'users', params: { UserUUID: store.getters['auth/getUuid'] } });
            }
            return next({ name: 'orders', params: { UserUUID: store.getters['auth/getUuid'] } });

          }
          else {
            next()
          }
        }
      },
      // {
      //   name: 'signup',
      //   path: 'signup',
      //   component: () => import('../pages/auth/signup/signup.vue')
      // },
      // {
      //   name: 'recover',
      //   path: 'recover',
      //   component: () => import('../pages/auth/recover-password/RecoverPassword.vue')
      // },
      // {
      //   name: 'reset-password',
      //   path: 'reset-password/:tokenID',
      //   component: () => import('../pages/auth/reset-password/ResetPassword.vue')
      // },
      // {
      //   name: 'verify-email',
      //   path: 'email/verify/:userID/:tokenID',
      //   component: () => import('../pages/auth/email-verify/emailVerify.vue')
      // },
      {
        path: '',
        redirect: { name: 'login' }
      }
    ]
  },
  {
    path: '/error',
    component: AuthLayout,
    children: [
      {
        name: '404',
        path: '404',
        component: () => import('../pages/error/404.vue')
      },
      {
        path: '',
        redirect: { name: '404' }
      }
    ]
  }
]



/* beforeEnter: (to, from, next) => {
          if (store.getters['auth/role']) {
            return next()
          }
          return next({ name: 'products' })
        }*/





const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  //  mode: process.env.VUE_APP_ROUTER_MODE_HISTORY === 'true' ? 'history' : 'hash',
  routes
})


export default router
